// import React, { useEffect } from 'react';
// const AnswerOptionsSelect = ({
//   AnswerArray, ArrayIndex,
//   Data, DataValue, DataType,
//   option
// }) => {
//   return (
//     <div className={
//       AnswerArray[ArrayIndex].correct_answer == option ?
//         "correct-option-result" :
//         Data == option ?
//           "wrong-option-result"
//           : ""
//     }>
//       <div className="answer-option-style"
//         style={{ display: 'flex' }}
//       >

//         {Data == option ?
//           <i class="fa fa-check-circle-o"></i>
//           : <i class="fa fa-circle-o"></i>
//         }
//         <div className={
//           Data == option ?
//             "active-option2"
//             : "passive-option2"
//         }>
//           {DataType == "text" &&
//             `${DataValue}`
//           }
//           {DataType == "image" &&
//             <img
//               src={DataValue}
//               style={{
//                 height: "100px",
//                 objectFit: 'contain',
//                 border: "0.5px solid #000"
//               }}
//             />
//           }
//           {DataType == "audio" &&
//             <audio
//               controls
//               src={`${DataValue}`}
//             >
//               {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
//               {/* <source src={`${DataValue}`} type="audio/mpeg" />
//             Your browser does not support the audio element. */}
//             </audio>
//           }
//           {DataType == "video" &&
//             <video width="400" controls>
//               <source src={`${DataValue}`} type="video/mp4"
//                 style={{ height: "150px", width: "200px" }}
//               />
//             </video>
//           }
//           {DataType == "youtube" &&
//             <iframe width="400" height="150" src={`https://www.youtube.com/embed/${DataValue}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//           }
//         </div>
//       </div>
//     </div>
//   )
// }
// export default function SecondResultScreenComponent(props) {
//   const {
//     oneQuestionData,
//     AnswerArray, ArrayIndex,
//     setQuestionsSolved, QuestionsSolved
//   } = props;
//   const [question, setQuestion] = React.useState(oneQuestionData.question)

//   const [AnswerSelected, setAnswerSelected] = React.useState("")
//   useEffect(() => {
//     if (AnswerArray[ArrayIndex].answerChoosen !== "") {
//       setAnswerSelected(AnswerArray[ArrayIndex].answerChoosen)
//     }
//   }, [])


//   return (
//     <div>
//       <div className="row">
//         <div className="col-md-8">
//           <div style={{ margin: "3px", padding: "10px", border: "1px solid #999", minHeight: "50vh" }}>
//             <div >
//               <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
//                 {oneQuestionData.question_number}. {oneQuestionData.question}
//               </h3>
//             </div>
//             <div className="question-description col-10">
//               {oneQuestionData.question_description_type == "text" &&
//                 <div style={{ fontSize: "16px", border: "1px solid #000", padding: "15px" }}>
//                   <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}
//                     style={{ fontSize: 16 }}
//                   />
//                   {/* {oneQuestionData.question_description} */}
//                 </div>
//               }
//               {oneQuestionData.question_description_type == "image" &&
//                 <img
//                   src={oneQuestionData.question_description}
//                   style={{
//                     height: "300px",
//                     objectFit: 'contain',
//                     border: "0.5px solid #000"
//                   }}
//                 />
//               }
//               {oneQuestionData.question_description_type == "audio" &&
//                 <audio controls>
//                   {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
//                   <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
//                   Your browser does not support the audio element.
//                 </audio>
//               }
//               {oneQuestionData.question_description_type == "video" &&
//                 <video width="400" controls>
//                   <source src={`${oneQuestionData.question_description}`} type="video/mp4"
//                     style={{ height: "150px", width: "200px" }}
//                   />
//                 </video>
//               }
//               {oneQuestionData.question_description_type == "youtube" &&
//                 <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_description}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//               }
//             </div>
//             {oneQuestionData.question_media !== "" &&
//               <div className="question-description col-10">
//                 {oneQuestionData.question_media_type == "text" &&
//                   `${oneQuestionData.question_media}`
//                 }
//                 {oneQuestionData.question_media_type == "image" &&
//                   <img
//                     src={oneQuestionData.question_media}
//                     style={{
//                       height: "300px",
//                       objectFit: 'contain',
//                       border: "0.5px solid #000"
//                     }}
//                   />
//                 }
//                 {oneQuestionData.question_media_type == "audio" &&
//                   <audio controls>
//                     {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
//                     <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
//                     Your browser does not support the audio element.
//                   </audio>
//                 }
//                 {oneQuestionData.question_media_type == "video" &&
//                   <video width="400" controls>
//                     <source src={`${oneQuestionData.question_media}`} type="video/mp4"
//                       style={{ height: "150px", width: "200px" }}
//                     />
//                   </video>
//                 }
//                 {oneQuestionData.question_media_type == "youtube" &&
//                   <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_media}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//                 }
//               </div>
//             }
//           </div>
//         </div>
//         <div className="col-md-4">
//           <div style={{  border: "1px solid #999", minHeight: "50vh" }}>
//             <AnswerOptionsSelect
//               AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
//               DataType={oneQuestionData.answer_media_type}
//               Data={AnswerSelected}
//               DataValue={oneQuestionData.option_1}
//               option={"option 1"}
//             />
//             <AnswerOptionsSelect
//               AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
//               DataType={oneQuestionData.answer_media_type}
//               Data={AnswerSelected}
//               DataValue={oneQuestionData.option_2}
//               option={"option 2"}
//             />
//             <AnswerOptionsSelect
//               AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
//               DataType={oneQuestionData.answer_media_type}
//               Data={AnswerSelected}
//               DataValue={oneQuestionData.option_3}
//               option={"option 3"}
//             />
//             <AnswerOptionsSelect
//               AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
//               DataType={oneQuestionData.answer_media_type}
//               Data={AnswerSelected}
//               DataValue={oneQuestionData.option_4}
//               option={"option 4"}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="center row">
//         <h3 className="col-5 ">
//           {AnswerArray[ArrayIndex].solved == 0 ?
//             <div className="wrong-option-result-2 ">
//               Question Unsolved
//             </div> :
//             <div>
//               {AnswerArray[ArrayIndex].correct_answer == AnswerArray[ArrayIndex].answerChoosen ?
//                 <div className="correct-option-result-2 ">
//                   Correct Answer
//                 </div>
//                 :
//                 <div className="wrong-option-result-2 ">
//                   Wrong Answer
//                 </div>
//               }
//             </div>
//           }

//         </h3>
//       </div>
//     </div>
//   )
// }


import React, { useEffect } from 'react';
const AnswerOptionsSelect = ({
  AnswerArray, ArrayIndex,
  Data, DataValue, DataType,
  option
}) => {
  return (
    <div className={
      AnswerArray[ArrayIndex].correct_answer == option ?
        "correct-option-result" :
        Data == option ?
          "wrong-option-result"
          : ""
    }>
      <div className="answer-option-style"
        style={{ display: 'flex' }}
      >

        {Data == option ?
          <i class="fa fa-check-circle-o"></i>
          : <i class="fa fa-circle-o"></i>
        }
        <div className={
          Data == option ?
            "active-option2"
            : "passive-option2"
        }>
          {DataType == "text" &&
            `${DataValue}`
          }
          {DataType == "image" &&
            <img
              src={DataValue}
              style={{
                height: "100px",
                maxWidth: "70vw",
                objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          }
          {DataType == "audio" &&
            <audio
              controls
              src={`${DataValue}`}
            >
              {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
              {/* <source src={`${DataValue}`} type="audio/mpeg" />
            Your browser does not support the audio element. */}
            </audio>
          }
          {DataType == "video" &&
            <video width="400" controls>
              <source src={`${DataValue}`} type="video/mp4"
                style={{ height: "150px", width: "200px" }}
              />
            </video>
          }
          {DataType == "youtube" &&
            <iframe width="400" height="150" src={`https://www.youtube.com/embed/${DataValue}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          }
        </div>
      </div>
    </div>
  )
}
export default function SecondResultScreenComponent(props) {
  const {
    oneQuestionData,
    AnswerArray, ArrayIndex,
    setQuestionsSolved, QuestionsSolved
  } = props;
  const [question, setQuestion] = React.useState(oneQuestionData.question)

  const [AnswerSelected, setAnswerSelected] = React.useState("")
  useEffect(() => {
    if (AnswerArray[ArrayIndex].answerChoosen !== "") {
      setAnswerSelected(AnswerArray[ArrayIndex].answerChoosen)
    }
  }, [])


  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div style={{ margin: "3px", padding: "10px", border: "1px solid #999" }}>
            <div >
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                {oneQuestionData.question_number}. {oneQuestionData.question}
              </h3>
            </div>
            <div className="question-description col-10">
              {oneQuestionData.question_description_type == "text" &&
                <div style={{ fontSize: "16px", padding: "5px" }}>
                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}
                    style={{ fontSize: 16 }}
                  />
                  {/* {oneQuestionData.question_description} */}
                </div>
              }
              {oneQuestionData.question_description_type == "image" &&
                <img
                  src={oneQuestionData.question_description}
                  style={{
                    height: "300px",
                    maxWidth: "70vw",
                    objectFit: 'contain',
                    border: "0.5px solid #000"
                  }}
                />
              }
              {oneQuestionData.question_description_type == "audio" &&
                <audio controls>
                  {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                  <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              }
              {oneQuestionData.question_description_type == "video" &&
                <video width="400" controls>
                  <source src={`${oneQuestionData.question_description}`} type="video/mp4"
                    style={{ height: "150px", width: "200px" }}
                  />
                </video>
              }
              {oneQuestionData.question_description_type == "youtube" &&
                <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_description}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              }
            </div>
            {oneQuestionData.question_media !== "" &&
              <div className="question-description col-10">
                {oneQuestionData.question_media_type == "text" &&
                  <div style={{ fontSize: "16px", border: "1px solid #000", padding: "15px", textAlign: "center" }}>
                    {oneQuestionData.question_media}
                  </div>
                }
                {oneQuestionData.question_media_type == "image" &&
                  <img
                    src={oneQuestionData.question_media}
                    style={{
                      height: "300px",
                      maxWidth: "70vw",
                      objectFit: 'contain',
                      border: "0.5px solid #000"
                    }}
                  />
                }
                {oneQuestionData.question_media_type == "audio" &&
                  <audio controls>
                    {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                    <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                }
                {oneQuestionData.question_media_type == "video" &&
                  <video width="400" controls>
                    <source src={`${oneQuestionData.question_media}`} type="video/mp4"
                      style={{ height: "150px", width: "200px" }}
                    />
                  </video>
                }
                {oneQuestionData.question_media_type == "youtube" &&
                  <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_media}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
            }
          </div>
        </div>
        <div className="col-md-4">
          <div style={{ border: "1px solid #999" }}>
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_1}
              option={"option 1"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_2}
              option={"option 2"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_3}
              option={"option 3"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_4}
              option={"option 4"}
            />
          </div>
        </div>
      </div>
      <div className="center row">
        <h3 className="col-5 ">
          {AnswerArray[ArrayIndex].solved == 0 ?
            <div className="wrong-option-result-2 ">
              Question Unsolved
            </div> :
            <div>
              {AnswerArray[ArrayIndex].correct_answer == AnswerArray[ArrayIndex].answerChoosen ?
                <div className="correct-option-result-2 ">
                  Correct Answer
                </div>
                :
                <div className="wrong-option-result-2 ">
                  Wrong Answer
                </div>
              }
            </div>
          }

        </h3>
      </div>
    </div>
  )
}
