import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function StartDemoExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.id
    AdsGETApiAuth({ slug: slug }, slugs.db_slug_demo_exam)
      .then((res) => {
        if (res.data.length == 1) {
          if (res.data[0].meta.exam_question_text !== undefined)
            setAllExamList(res.data[0])
          else {
            setNotFound(true)
          }

          setExamFetch(true)
          // })
          // .finally(() => {
          setReload(false)
          // })
        }
        else {
          setNotFound(true)
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  const handle = useFullScreenHandle();
  useEffect(() => {
    console.log("asdsahgdh")
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()
  
    fetchExam(location.pathname.slice(slugs.start_demo_exam.length + 1))
  }, [])

  return (
    // <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
    //   <div>
    //     <div className="row">
    //       <div className="col-sm-2 col-1"></div>
    //       <div className="col-sm-8 col-10 form-view m-3">
    //         {ExamFetch &&
    //           <>
    //             {NotFoundData ?
    //               <NotFound /> :
    //               <>
    //                 <FirstScreenExam
    //                   ExamData={ExamData}
    //                   setReload={setReload}
    //                   choose={choose}
    //                   setChoose={setChoose}
    //                   AnswerArray={AnswerArray}
    //                   setAnswerArray={setAnswerArray}
    //                   ResultData={ResultData} setResultData={setResultData}
    //                 />
    //               </>
    //             }
    //           </>
    //         }
    //       </div>
    //     </div>
    //   </div>
    //   {reload &&
    //     <div className="modal" style={{ padding: "50vw" }}>
    //       <div className="dot-pulse"></div>
    //     </div>
    //   }
    // </div>
    <div
    style={{ backgroundColor: "#fff" }}
    >
      <FullScreen handle={handle}>
        <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
        {/* <div
          style={{ backgroundColor: "#fff" }}
        > */}
          {/* <div className="row">
      <div className="col-md-1">
      </div>
      <div className="col-md-10" style={{ padding: "10px" }}> */}
          <div className="form-view m-3">

            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :

                  <FirstScreenExam
                    ExamData={ExamData}
                    setReload={setReload}
                    choose={choose}
                    setChoose={setChoose}
                    AnswerArray={AnswerArray}
                    setAnswerArray={setAnswerArray}
                    ResultData={ResultData} setResultData={setResultData}
                  />
                }
              </>
            }
          </div>
          {/* </div> */}
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}